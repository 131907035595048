//dependencias
import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";


//imagenes
import f34 from "../../img/p6_img1.png";
import f35 from "../../img/p6_sig.png";
import f36 from "../../img/reproducir.png";
//componentes
import "./Cancion.css";
import "./Cancion2.css";
//import Alert from "../../modal/alert";

class CancionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blobObject: null,
      opcion: 0,
      archivo: "",
      blobURL: "",
      blobURL1: "",
      archivoab1: "",
      archivo1: "",
      blobURL2: "",
      archivoab2: "",
      archivo2: "",
      blobURL3: "",
      archivoab3: "",
      archivo3: "",
      blobURL4: "",
      isRecording: [false, false, false],
      isPaused: [false, false, false],
      botongrOn: [false, false, false],
      botonrec: [false, false, false],
      ifechaevo: [],
      seconds: 0,
      archivos: [3],
      onInicio: [false, false, false],
      showRecord: [false, false, false],
      onParar: [true, true, true],
      loading: false,
      mensajealerta: false,
      codigohash: "",
      activoOn: false,
      cancion: "",
      can1On: false,
      can2On: false,
      can3On: false,
      can4On: false,
      can5On: false,
      seconds1: 0,
      email: "",
      evolucionOn: false,
      tituloM: "",
      cuerpoM: "",
      retardo: [50, 200, 300],
      transicion: [10, 2000, 3000],
      contenedorOn: [true, false, false, false, false, false]
    };
  }

  limpiar = () => {
    const { blobURL, blobURL1, blobURL2, blobURL3 } = this.state;
    URL.revokeObjectURL(blobURL);
    URL.revokeObjectURL(blobURL1);
    URL.revokeObjectURL(blobURL2);
    URL.revokeObjectURL(blobURL3);
    this.setState({
      blobObject: null,
      opcion: 0,
      archivo: "",
      blobURL: "",
      blobURL1: "",
      archivo1: "",
      archivoab1: "",
      blobURL2: "",
      archivo2: "",
      archivoab2: "",
      archivoab3: "",
      blobURL3: "",
      archivo3: "",
      blobURL4: "",
      isRecording: [false, false, false],
      isPaused: [false, false, false],
      botongrOn: [false, false, false],
      botonrec: [false, false, false],
      ifechaevo: [],
      seconds: 0,
      archivos: [3],
      onInicio: [false, false, false],
      showRecord: [false, false, false],
      onParar: [true, true, true],
      loading: false,
      mensajealerta: false,
      codigohash: "",
      activoOn: false,
      cancion: "",
      can1On: false,
      can2On: false,
      can3On: false,
      can4On: false,
      can5On: false,
      seconds1: 0,
      evolucionOn: false,
      tituloM: "",
      cuerpoM: "",
      contenedorOn: [true, false, false, false, false, false],
      email: ""
    });
  };

  startOrPauseRecording = e => {
    //console.log(e)
    let index = parseInt(e.target.getAttribute("data-index"));
    //console.log(index)
    let {
      onInicio,
      onParar,
      isPaused,
      isRecording,
      botonrec,
      showRecord
    } = this.state;
    //console.log(onInicio)
    onInicio[index] = true;
    showRecord[index] = true;
    onParar[index] = false;
    this.setState({ onInicio, onParar, showRecord });
    if (isPaused[index]) {
      isPaused[index] = false;
      this.setState({ isPaused });
    } else if (isRecording[index]) {
      isPaused[index] = true;
      this.setState({ isPaused });
    } else {
      isRecording[index] = true;
      botonrec[index] = true;
      this.setState({ isRecording, botonrec });
    }
    //console.log(isRecording, onParar)
  };

  stopRecording = e => {
    //console.log(typeof e)
    let index = null;
    if (typeof e === "number") {
      index = e;
    } else {
      index = parseInt(e.target.getAttribute("data-index"));
    }

    let { isRecording, onInicio, onParar } = this.state;
    isRecording[index] = false;
    onInicio[index] = false;
    onParar[index] = true;
    this.setState({ isRecording, onInicio, onParar });
  };

  vaciarblob = e => {
    let index = parseInt(e.target.getAttribute("data-index"));
    //console.log(index)
    let {
      isRecording,
      isPaused,
      botongrOn,
      botonrec,
      onInicio,
      onParar
    } = this.state;
    isRecording[index] = false;
    isPaused[index] = false;
    botongrOn[index] = false;
    botonrec[index] = false;
    onInicio[index] = false;
    onParar[index] = true;
    this.setState({
      isRecording,
      isPaused,
      botongrOn,
      botonrec,
      onInicio,
      onParar
    });
    switch (index) {
      case 0:
        this.setState({
          blobURL1: "",
          archivo1: "",
          blobURL: "",
          blobObject: null
        });
        break;
      case 1:
        this.setState({
          blobURL2: "",
          archivo2: "",
          blobURL: "",
          blobObject: null
        });
        break;
      case 2:
        this.setState({
          blobURL3: "",
          archivo3: "",
          blobURL: "",
          blobObject: null
        });
        break;
    }
    this.setState({
      archivo: "",
      ifechaevo: [],
      seconds: 0
    });
  };

  onStop = (id, blobObject) => {
    console.log(id, blobObject);
    if (id === "audio1") {
      this.setState({ archivo1: blobObject });
    }
    if (id === "audio2") {
      this.setState({ archivo2: blobObject });
    }
    if (id === "audio3") {
      this.setState({ archivo3: blobObject });
    }
    let rw = "";
    // let global = this;
    let blobURL = blobObject.blobURL;
    // let request = new XMLHttpRequest();
    // request.open('GET', blobURL, true);
    // request.responseType = 'blob';
    // request.onload = function() {
    //     let reader = new FileReader();
    //     reader.readAsDataURL(request.response);
    //     reader.onload = function(e){
    //         rw=e.target.result;
    //         // if (id==='audio1'){ global.setState({ archivo1:e.target.result })}
    //         // if (id==='audio2'){ global.setState({ archivo2:e.target.result })}
    //         // if (id==='audio3'){ global.setState({ archivo3:e.target.result })}

    //     }
    // };

    let botongrOn = this.state.botongrOn;
    if (blobURL.length > 0) {
      // console.log(id)
      switch (id) {
        case "audio1":
          botongrOn[0] = true;
          //console.log(archivo)
          this.setState({ blobURL1: blobURL, rw, botongrOn });
          break;
        case "audio2":
          botongrOn[1] = true;
          this.setState({ blobURL2: blobURL, rw, botongrOn });
          break;
        case "audio3":
          botongrOn[2] = true;
          this.setState({ blobURL3: blobURL, rw, botongrOn });
          break;
      }
    }
    // request.send();
  };

  Consultar = () => {
    if (this.state.evolucionOn === true) {
      this.setState({
        evolucionOn: false,
        ifechaevo: []
      });
    } else {
      if (this.state.email.length > 0) {
        axios
          .post(
            "https://fehensa.com.ve/cancion/grabarSonido.php/?email=" +  this.state.email1 +  "&boton=ConsultarFechas"
          )
          //console.log('https://fehensa.com.ve/cancion/grabarSonido.php/?email='+ this.state.email1 + '&boton=ConsultarFechas')
          .then(res => {
            if (res.data) {
              let xfechaevo = res.data;
              this.setState({
                loading: false,
                ifechaevo: xfechaevo,
                evolucionOn: true
              });
            } else {
              this.setState({
                loading: false,
                mensajealerta: true,
                tituloM: "Menu Unir Sonidos",
                cuerpoM: "No se encontraron Sonidos para este EMAIL Gracias."
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          loading: false,
          tituloM: "Menu Unir Sonidos",
          cuerpoM: "Debe indicar un EMAIL Gracias."
        });
      }
    }
  };

  guardarSonido = () => {
    const {
      email,
      can1On,
      can2On,
      can3On,
      can4On,
      can5On,
      archivo1,
      archivo2,
      archivo3
    } = this.state;
    let pista = "";
    if (can1On) {
      pista = "Cancion1";
    }
    if (can2On) {
      pista = "Cancion2";
    }
    if (can3On) {
      pista = "Cancion3";
    }
    if (can4On) {
      pista = "Cancion4";
    }
    if (can5On) {
      pista = "Cancion5";
    }

    // var a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style = "display: none";
    // a.href = this.state.blobURL1;
    // a.download = "test.mp3";
    // a.click();

    // var a = document.createElement("a");
    // document.body.appendChild(a);
    // a.style = "display: none";
    // a.href = this.state.blobURL2;
    // a.download = "test.mp3";
    // a.click();
    if (this.state.email.length > 0 && pista.length > 0) {
      let xdata = new FormData();
      console.log(this.state.archivo1);
      xdata.append("audio1", this.state.archivo1.blob, "audio1x.mp3");
      xdata.append("name1", String(this.state.email));
      xdata.append("email", this.state.email);
      xdata.append("boton", "Guardar");
      xdata.append("pista", pista);
      let config = {
        headers: {
          "Content-Type": false
          // 'Content-Type' : 'multipart/form-data'
        }
      };
      const xurl = "https://fehensa.com.ve/cancion/grabarSonido.php";
      axios
        .post(xurl, xdata, config)

        .then(res => {
          if (res.data.variable1) {
            this.setState(
              {
                loading: false,
                contenedorOn: [true, false, false, false, false, false]
                // mensajealerta: true,
                // tituloM: 'Menu Unir Pistas',
                // cuerpoM: 'Los Audios se Guardaron con Exito',
              },
              this.limpiar()
            );
          } else {
            this.setState({
              loading: false,
              contenedorOn: [false, false, false, false, false, true]
              // loading:false,
              // mensajealerta: true,
              // tituloM: 'Menu Unir Pistas',
              // cuerpoM: 'Ocurrio un Problema 11Verifique.',
            });
          }
        });
    } else {
      this.setState({
        loading: false,
        mensajealerta: true,
        tituloM: "Menu Unir Pistas",
        cuerpoM: "Debe incluir un EMAIL y elejir una PISTA."
      });
    }
  };

  consultarSonido = (e) => {
    console.log(e)
    let data = new FormData();
    data.append("email", e);
    data.append("boton", "Consultar");
    let config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const xurl = "https://fehensa.com.ve/cancion/grabarSonido.php";
    axios.post(xurl, data, config).then(res => {
      //console.log("paso: "+res.data.variable1)
      if (res.data.variable1) {
        let index = 0;
        let audi1 = res.data.variable1;
        let audi2 = res.data.variable2;
        let audi3 = res.data.variable3;
        let pist = res.data.variable4;
        if (pist === "Cancion1") {
          this.cancionOn(1);
        }
        if (pist === "Cancion2") {
          this.cancionOn(2);
        }
        if (pist === "Cancion3") {
          this.cancionOn(3);
        }
        if (pist === "Cancion4") {
          this.cancionOn(4);
        }
        if (pist === "Cancion5") {
          this.cancionOn(5);
        }

        this.setState(
          {
            archivo1: audi1,
            archivo2: audi2,
            archivo3: audi3,
            blobURL1: audi1,
            blobURL1: audi1,
            blobURL2: audi2,
            blobURL3: audi3,
            evolucionOn: false
          },
          this.setState({ loading: false })
        );
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Unir Pistas",
          cuerpoM: "Ocurrio un Problema 22Verifique."
        });
      }
    });
  };

  eliminar = e => {
    //console.log(e)
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com.ve/cancion/grabarSonido.php/?email=" +
            e +
            "&boton=Eliminar"
        )
        .then(res => {
          if (res.data) {
            this.setState({
              evolucionOn: false,
              ifechaevo: [],
              mensajealerta: true,
              tituloM: "Menu Unir Sonido",
              cuerpoM: "Se elimino con exito el sonido."
            });
            this.spineractivar("consul");
          }
        });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  convertDataURIToBinary = dataURI => {
    let i = 0;
    let BASE64_MARKER = ";base64,";
    let base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (i = 0; i < rawLength; i++) {
      if (i < 15000 || i > 18000) {
        array[i] = raw.charCodeAt(i);
      }
    }
    //console.log('iiiii',i)
    return array;
  };
  tiempo1 = () => {
    this.myInterval = setInterval(() => {
      const { seconds1, can1On, can2On, can3On, can4On, can5On } = this.state;

      if (seconds1 > 0) {
        this.setState(({ seconds1 }) => ({
          seconds1: seconds1 + 1
        }));
      }
      if (can1On) {
        if (seconds1 === 9) {
          this.grabar1();
        }
        if (seconds1 === 27) {
          this.grabar2();
        }
        if (seconds1 === 41) {
          this.grabar3();
          clearInterval(this.myInterval);
        }
      }
      if (can2On) {
        if (seconds1 === 10) {
          this.grabar1();
        }
        if (seconds1 === 20) {
          this.grabar2();
        }
        if (seconds1 === 38) {
          this.grabar3();
          clearInterval(this.myInterval);
        }
      }
      if (can3On) {
        if (seconds1 === 12) {
          this.grabar1();
        }
        if (seconds1 === 27) {
          this.grabar2();
        }
        if (seconds1 === 41) {
          this.grabar3();
          clearInterval(this.myInterval);
        }
      }
      if (can4On) {
        if (seconds1 === 10) {
          this.grabar1();
        }
        if (seconds1 === 20) {
          this.grabar2();
        }
        if (seconds1 === 29) {
          this.grabar3();
          clearInterval(this.myInterval);
        }
      }
      if (can5On) {
        if (seconds1 === 9) {
          this.grabar1();
        }
        if (seconds1 === 23) {
          this.grabar2();
        }
        if (seconds1 === 33) {
          this.grabar3();
          clearInterval(this.myInterval);
        }
      }
    }, 1000);
  };

  grabar1 = () => {
    const audioG = document.getElementById("out1");
    audioG.play();
  };
  grabar2 = () => {
    const audioG = document.getElementById("out2");
    audioG.play();
  };
  grabar3 = () => {
    const audioG = document.getElementById("out3");
    audioG.play();
    this.setState({ efectos1On: false, efectos2On: false, efectos3On: false });
  };

  onComplete(index) {
    this.stopRecording(index);
    setTimeout(() => {
      let showRecord = this.state.showRecord;
      showRecord[index] = false;
      this.setState({ showRecord });
    }, 1000);
  }

  cancionOn = e => {
    //console.log(e);
    let nombrearchi = "";
    if (e === 1) {
      nombrearchi = "AUDIO1.mp3";
      this.setState({
        can1On: true,
        can2On: false,
        can3On: false,
        can4On: false,
        can5On: false
      });
    }
    if (e === 2) {
      nombrearchi = "AUDIO2.mp3";
      this.setState({
        can1On: false,
        can2On: true,
        can3On: false,
        can4On: false,
        can5On: false
      });
    }
    if (e === 3) {
      nombrearchi = "AUDIO3.mp3";
      this.setState({
        can1On: false,
        can2On: false,
        can3On: true,
        can4On: false,
        can5On: false
      });
    }
    if (e === 4) {
      nombrearchi = "AUDIO4.mp3";
      this.setState({
        can1On: false,
        can2On: false,
        can3On: false,
        can4On: true,
        can5On: false
      });
    }
    if (e === 5) {
      nombrearchi = "AUDIO5.mp3";
      this.setState({
        can1On: false,
        can2On: false,
        can3On: false,
        can4On: false,
        can5On: true
      });
    }
    this.setState({
      cancion: nombrearchi
    });
  };

  onChange = e => {
    // console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  spineractivar = (e, i) => {
    if (this.state.loading === true) {
      this.setState({
        loading: false
      });
    } else {
      this.setState({
        loading: true
      });
      if (e === "tabla") {
        this.consultarSonido(i);
      }
      if (e === "almacenar") {
        this.guardarSonido();
      }
      if (e === "consul") {
        this.Consultar();
      }
      if (e === "guardarsonido") {
        this.guardarSonido();
      }
    }
  };

  crearblobfinal = () => {
    let {
      blobURL1,
      blobURL4,
      archivo1,
      archivo2,
      archivo3,
      archivoab1,
      archivoab2,
      archivoab3
    } = this.state;
    this.setState({ seconds1: 1 });
    this.tiempo1();
    const audioP = document.getElementById("out0");
    audioP.play();

    //var blob= new Blob(data, {type : 'audio/mp3'}); // el blob;
  };

  conteneaccion = e => {
    let { contenedorOn, showRecord } = this.state;
    switch (e) {
      case "0":
        if (this.state.email.length > 1) {
          contenedorOn[0] = false;
          contenedorOn[1] = true;
          this.setState({ contenedorOn });
        }
        break;
      case "1":
        if (this.state.cancion.length > 1) {
          contenedorOn[1] = false;
          contenedorOn[2] = true;
          // showRecord[0]='true' ,showRecord
          this.setState({ contenedorOn });
        }
        break;
      case "2":
        if (this.state.blobURL1.length > 1) {
          contenedorOn[2] = false;
          contenedorOn[3] = true;
          this.setState({ contenedorOn });
        }
        break;
      case "3":
        if (this.state.blobURL2.length > 1) {
          contenedorOn[3] = false;
          contenedorOn[4] = true;
          this.setState({ contenedorOn });
        }
        break;
      case "4":
        if (this.state.blobURL3.length > 1) {
          contenedorOn[4] = false;
          contenedorOn[5] = true;
          this.setState({ contenedorOn });
        }
        break;
      case "5":
        contenedorOn[5] = false;
        contenedorOn[6] = true;
        this.setState({ contenedorOn });
        break;
      case "4":
        contenedorOn[6] = false;
        contenedorOn[1] = true;
        this.setState({ contenedorOn });
        break;
    }
  };

  componentDidMount(){
    const values = qs.parseUrl(window.location.search);
    const id = values.query['item'];
    // const id ="10774";
    this.consultarSonido(id);  
  }


  render() {
    const {cancion, blobURL1, blobURL3, blobURL2,
     
    } = this.state;
    console.log(this.state.showRecord[0]);
    return (
      <div className="principal row">

          <div className="contenedor6" id="p6">
            <div className="p6info">
              <img className="titulo" src={f34} />
              <div className="linea">
                <audio
                  className="out0"
                  id="out0"
                  ref=""
                  controls="controls"
                  src={cancion}
                ></audio>
                <audio
                  className="out1"
                  id="out1"
                  ref=""
                  controls="controls"
                  src={blobURL1}
                ></audio>
                <audio
                  className="out2"
                  id="out2"
                  ref=""
                  controls="controls"
                  src={blobURL2}
                ></audio>
                <audio
                  className="out3"
                  id="out3"
                  ref=""
                  controls="controls"
                  src={blobURL3}
                ></audio>
                <div className="btn">
                  <img
                    src={f36}
                    id="repro"
                    className="repro"
                    onClick={this.crearblobfinal}
                  />
                </div>
              </div>
              <div className="row">
                <img
                  className="sig"
                  src={f35}
                  //onClick={this.spineractivar.bind(this, "almacenar")}
                />
              </div>
            </div>
          </div>
      </div>
    );
  }
}
export default CancionAction;
